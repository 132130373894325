import { useUserData } from '~~/stores/userData'; 
import { usePicturesStore } from '~~/stores/pictures'; 
import { useOpencall } from '~~/stores/opencall';

type UserDataReg = {
    email: string
    first_name: string
    last_name: string
}

type UserDataLogin = {
    email: string
    password: string
}

type UserDataPassword = {
    newPassword: string
    repeatPassword: string
}

type UserDataSecurity = {
    email: string
    password: string
    new_password: string
}

type Picture = {
    file: any
    name: string
    period: string
    desc: string
    format: string
    width: string
    height: string
    materials: string
}

type Opencall = {
    name: string
    conception: string
    task: string
    constraints: string
    deadline_date: string
    location: string
    file: any    
};

export function useCheckValidation(first_name: string, last_name: string, email: string, checkDefault: boolean, acceptTerms: Ref<Boolean>) {
    let check = checkDefault
    const store = useUserData();
    
    if (!first_name.length) {
        store.showErrorMessage({ first_name: "Введите имя пользователя" })
        check = false
    }  
    if (!last_name.length) {
        store.showErrorMessage({ last_name: "Введите фамилию пользователя" })
        check = false
    }  
    if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) === false
    ) {
        store.showErrorMessage({ email: "Некорректный e-mail" })
        check = false
    }
    if (!email.length) {
        store.showErrorMessage({ email: "Введите e-mail" })
        check = false
    }
    if (!acceptTerms.value) {
        store.showErrorMessage({ acceptTerms: "Согласие на обработку персональных данных является обязательным" })
        check = false
    }  
    if(Object.entries(store.message).length === 0) check = true
    return {
        check
    }
}

export function useCheckValidationLogin(formData: UserDataLogin, checkDefault: boolean) {
    let check = checkDefault
    const store = useUserData()

    if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.email) === false
    ) {
        store.showErrorMessage({ email: "Некорректный e-mail" })
        check = false
    }
    if (!formData.email.length) {
        store.showErrorMessage({ email: "Введите e-mail" })
        check = false
    }
    if (!formData.password.length) {
        store.showErrorMessage({ password: "Введите пароль" })
        check = false
    } 
    if (formData.password!.length && formData.password!.length < 6) {
        store.showErrorMessage({ password: "Пароль не меньше 6 символов" })
        check = false
    }       
    if(Object.entries(store.message).length === 0) check = true
    return {
        check
    }
}

export function useCheckPassword(formData: UserDataPassword, checkDefault: boolean) {
    let check = checkDefault
    const store = useUserData()
    
    if (!formData.newPassword.length || formData.newPassword.length < 8) {
        store.showErrorMessage({ newPassword: "Введите пароль не меньше 8 символов" })
        check = false
    } 
    if (!formData.repeatPassword.length || formData.repeatPassword.length < 8) {
        store.showErrorMessage({ repeatPassword: "Введите пароль не меньше 8 символов" })
        check = false
    } 
    if (formData.repeatPassword !== formData.newPassword) {
        store.showErrorMessage({ errorMessage: "Пароли не совпадают" })
        check = false
    }   
    if(Object.entries(store.message).length === 0) check = true
    return {
        check
    }
}

export function useCheckEmail(formData: { email: string }, checkDefault: boolean) {
    let check = checkDefault
    const store = useUserData()
    
    if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.email) === false
    ) {
        store.showErrorMessage({ errorMessage: "Некорректный e-mail" })
        check = false
    }  
    if(Object.entries(store.message).length === 0) check = true
    return {
        check
    }
}

export function useCheckSecurityProfile(formData: UserDataSecurity, checkDefault: boolean) {
    let check = checkDefault
    const store = useUserData()
    let currentPassword = store.user.password
    if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.email) === false
    ) {
        store.showErrorMessage({ email: "Некорректный e-mail" })
        check = false
    }
    if (!formData.email.length) {
        store.showErrorMessage({ email: "Введите e-mail" })
        check = false
    }    
    if (!formData.new_password.length || formData.new_password.length < 6) {
        store.showErrorMessage({ newPassword: "Введите пароль не меньше 6 символов" })
        check = false
    }
    if (formData.password === formData.new_password) {
        store.showErrorMessage({ newPassword: "Пароли должны отличаться" })
        check = false
    }      
    if (!formData.password.length || formData.password.length < 6) {
        store.showErrorMessage({ passwordMessage: "Введите пароль не меньше 6 символов" })
        check = false
    } 
    if (formData.password !== currentPassword) {
        store.showErrorMessage({ passwordMessage: "Неверный пароль" })
        check = false
    }    
    if(Object.entries(store.message).length === 0) check = true
    return {
        check
    }
}

export function useCheckNotificationsProfile(formData: any, checkDefault: boolean) {
    let check = checkDefault
    const store = useUserData()

    if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.emailNews) === false
    ) {
        store.showErrorMessage({ emailNews: "Некорректный e-mail" })
        check = false
    }
    if (!formData.emailNews.length) {
        store.showErrorMessage({ emailNews: "Введите e-mail" })
        check = false
    }    
    if(Object.entries(store.message).length === 0) check = true
    return {
        check
    }
}

export function useCheckMainProfile(formData: any, checkDefault: boolean) {
    let check = checkDefault
    const store = useUserData()

    if (!formData.first_name.length) {
        store.showErrorMessage({ first_name: "Введите имя" })
        check = false
    }    
    if (!formData.last_name.length) {
        store.showErrorMessage({ last_name: "Введите фамилию" })
        check = false
    }    
    if(Object.entries(store.message).length === 0) check = true
    return {
        check
    }
}

export function useCheckPicture(formData: Picture, checkDefault: boolean) {
    let check = checkDefault
    const store = usePicturesStore()

    if (!formData.name.length) {
        store.showErrorMessage({ name: "Введите название работы" })
        check = false
    }    
    if (!formData.desc.length) {
        store.showErrorMessage({ desc: "Введите описание работы" })
        check = false
    }    
    if (!formData.file) {
        store.showErrorMessage({ file: "Загрузите работу" })
        check = false
    }
    if (!formData.period) {
        store.showErrorMessage({ period: "Введите период создания работы" })
        check = false
    }
    if (!formData.width) {
        store.showErrorMessage({ width: "Введите ширину работы" })
        check = false
    }
    if (!formData.height) {
        store.showErrorMessage({ height: "Введите высоту работы" })
        check = false
    }
    if (!formData.materials) {
        store.showErrorMessage({ materials: "Введите материалы, использованные в работе" })
        check = false
    }
    if(Object.entries(store.message).length === 0) check = true
    return {
        check
    }
}

export function useCheckOpencall(formData: Opencall, checkDefault: boolean) {
    let check = checkDefault
    const store = useOpencall();

    if (!formData.name.length) {
        store.showErrorMessage({ name: "Введите название Опен-колла" })
        check = false
    }    
    if (!formData.conception.length) {
        store.showErrorMessage({ conception: "Опишите концепцию" })
        check = false
    }    
    if (!formData.task) {
        store.showErrorMessage({ task: "Опишите главную задачу" })
        check = false
    }
    if (!formData.constraints) {
        store.showErrorMessage({ constraints: "Укажите условия" })
        check = false
    }
    if (!formData.deadline_date) {
        store.showErrorMessage({ deadline_date: "Выберите дату" })
        check = false
    }
    if (!formData.file) {
        store.showErrorMessage({ file: "Загрузите обложку" })
        check = false
    }
    if(Object.entries(store.message).length === 0) check = true
    return {
        check
    }
}

export function useCheckFeedback(formData: any, checkDefault: boolean) {
    let check = checkDefault;
    let message = {};

    if (!formData.name.length) {
        message = {...message, name: "Введите имя"}; 
        check = false
    }    
    if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.email) === false
    ) {
        message = {...message, email: "Некорректный e-mail"}; 
        check = false
    }
    if (!formData.email.length) {
        message = {...message, email: "Введите e-mail"}; 
        check = false
    }
    if (!formData.desc.length) {
        message = {...message, desc: "Введите сообщение"}; 
        check = false
    }
    if(Object.entries(message).length === 0) check = true
    return {
        check,
        message
    }
}
